import service from '@/utils/request.js';

export const getPathList = async (json) => {
  return service({
      url: '/v1/Index/Index/getPathList',
      method: 'post',
      data: json
  })
}
export const getIndex = async (json) => {
  return service({
      url: '/v1/Index/Index/getIndex',
      method: 'post',
      data: json
  })
}
export const getCommonList = async (json) => {
  return service({
      url: '/v1/Index/Index/getCommonList',
      method: 'post',
      data: json
  })
}
export const seekAdd = async (json) => {
  return service({
      url: '/v1/Admin/Seek/add',
      method: 'post',
      data: json
  })
}
export const getCarList = async (json) => {
  return service({
      url: '/v1/Index/Index/getCarList',
      method: 'post',
      data: json
  })
}
export const RentConsult = async (json) => {
  return service({
      url: '/v1/Admin/RentConsult/add',
      method: 'post',
      data: json
  })
}
export const getCodeList = async (json) => {
  return service({
      url: '/v1/Index/Index/getCodeList',
      method: 'post',
      data: json
  })
}