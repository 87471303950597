<template>
  <div class="footer-box">
    <div class="labels">
      <div class="label" v-for="(item, index) in routeLabel" :key="index">
        <div class="line" v-if="index"></div>
        <div class="cursor" @click="_click(item)">{{ item.title }}</div>
      </div>
    </div>
    <div class="labels rich">
      <div class="label" v-for="(item, index) in richList" :key="index">
        <div class="line" v-if="index"></div>
        <div class="cursor">{{ item.label }}</div>
      </div>
    </div>
    <div>{{ version }}</div>
  </div>
</template>
<script>
export default {
  name: 'footer-comp',
  data() {
    return {
      richList: [
        { label: 'Impressum', url: '' },
        { label: 'AGB', url: '' },
        { label: 'Datenschutz', url: '' },
      ],
      version: 'Copyright@2020 fitt-group.com. 保留所有权利',
    }
  },
  computed: {
    routeLabel() {
      let { config } = this.$store.getters;
      return config.column_list || [];
    }
  },
  methods: {
    _click(row) {
      let { type, jump_url, url } = row;
      let jumpUrl = jump_url || url;
      if (!jumpUrl) return;
      if (type !== 3) return this.$router.push(jumpUrl);
      let a = document.createElement('a');
      a.href = jumpUrl;
      a.target = '_blank';
      a.click();
    },
  }
}
</script>
<style lang="scss" scoped>
.footer-box {
  height: 204px;
  background: #0D1526;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  font-size: 16px;
  flex: none;
  .labels {
    display: flex;
    font-size: 18px;
    .label {
      display: flex;
      align-items: center;
      .line {
        height: 19px;
        border-left: 1px solid #c9c9c9;
        margin: 0 24px;
      }
    }
    &.rich {
      font-size: 16px;
      margin: 34px 0 24px 0;
    }
  }
}
</style>