
import Vue from 'vue';
import App from './App.vue';
import store from './store'

import router from '@/router'

import TDesign from 'tdesign-vue';
// 引入组件库的少量全局样式变量
import 'tdesign-vue/es/style/index.css';
Vue.use(TDesign);
document.title = 'FITT德国青年旅行社';

import '@/styles/index.scss';
import "swiper/css/swiper.min.css";

// store.dispatch('app/getConfig');
import './permission.js';

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
