<template>
  <div>
    <div class="first-box fs-12">
      <div class="container-width flex flex-b">
        <div>
          <!-- <span>{{ '让旅行更幸福' }}</span>
          <t-icon class="ml-30 mr-8" name="internet" />
          <span>{{ '网站无障碍' }}</span> -->
        </div>
        <div>
          <span class="right-top cursor ml-30" v-for="(item, index) in list" :key="index" @click="_click(item)">{{ item.title }}</span>
        </div>
      </div>
    </div>
    <div class="second container-width flex flex-b">
      <div class="flex">
        <img :src="require('@/assets/logo.png')" alt="">
        <!-- <t-button shape="circle" theme="primary">
          <t-icon slot="icon" name="location" />
        </t-button>
        <div class="ml-10">
          <span class="fs-16">{{ '德国站' }}</span>
          <div class="label">{{ '切换城市' }}</div>
        </div> -->
      </div>
      <div>
        <div class="contact align-right">
          <div>
            <span class="label">{{ '对外服务时间：' }}</span>
            <span>{{'周一到周五 9:00-18:00， 周六 10:00-14:00 '}}</span>
          </div>
          <!-- <div>
            <span class="label">{{ '节假日：' }}</span>
            <span>{{'周六 10:00/14:00 , 周日 13:00/18:00'}}</span>
          </div> -->
          <div>
            <span class="label">{{ '24小时热线电话：' }}</span>
            <span>{{'0211-95599955， 0221-30060520'}}</span>
          </div>
          <div>
            <span class="label">{{ '总部地址：' }}</span>
            <span>{{'Schadowstr. 84，40212 Düsseldorf'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="three">
      <div class="container-width flex">
        <div class="nav-item" :class="{active: currentUrl == item.url || currentUrl == item.jump_url}" v-for="(item, index) in navList" :key="index" @click="routeChange(index, item)">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'header-comp',
  computed: {
    currentUrl() {
      return this.$route.path;
    },
    navList() {
      let { config } = this.$store.getters;
      return config.column_list || [];
    },
    list() {
      let { config } = this.$store.getters;
      return config.column_list_1 || [];
    },
  },
  methods: {
    _click(row) {
      let { type, jump_url } = row;
      if (!jump_url || this.currentUrl == jump_url) return;
      if (type !== 3) return this.$router.push(jump_url);
      let a = document.createElement('a');
      a.href = jump_url;
      a.target = '_blank';
      a.click();
    },
    routeChange(index, row) {
      let { type, jump_url, url } = row;
      let jumpUrl = jump_url || url;
      let navUrl = this.navList[index].jump_url || this.navList[index].url;
      if (!jumpUrl || this.currentUrl == navUrl) return;
      if (type !== 3) return this.$router.push(jumpUrl);
      let a = document.createElement('a');
      a.href = jumpUrl;
      a.target = '_blank';
      a.click();
    }
  }
}
</script>
<style lang="scss" scoped>
.first-box {
  height: 40px;
  background: #F5F5F5;
  color: $normal;
  .container-width {
    height: 40px;
    line-height: 1;
    .right-top {
      &:hover {
        color: $theme;
        text-decoration: underline;
      }
    }
  }
}
.second {
  height: 104px;
  font-size: 14px;
  color: $title;
  .label {
    color: $normal;
  }
  .contact {
    line-height: 28px;
  }
}
.three {
  height: 60px;
  background: $theme;
  .nav-item {
    width: 100px;
    height: 58px;
    line-height: 58px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    border: 1px solid $theme;
    cursor: pointer;
    &.active {
      background: #fff;
      color: $theme;
    }
  }
}
</style>