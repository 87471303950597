import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import Layout from '@/layout/index.vue';

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: () => import('@/pages/home/index.vue')
      },
      {
        path: '/plane',
        component: () => import('@/pages/plane/index.vue')
      },
      {
        path: '/rental',
        component: () => import('@/pages/rental/index.vue')
      },
      {
        path: '/travel',
        component: () => import('@/pages/travel/index.vue')
      },
      {
        path: '/signing',
        component: () => import('@/pages/signing/index.vue')
      },
      {
        path: '/trusteeship',
        component: () => import('@/pages/trusteeship/index.vue')
      },
      {
        path: '/estate',
        component: () => import('@/pages/estate/index.vue')
      },
    ]
  }
];
export default new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes,
})