import Axios from 'axios';
const service = Axios.create({
  // 公共接口--这里注意后面会讲
  baseURL: process.env.NODE_ENV === 'development'?'/proxyApi': 'https://gh.zindex.club/',
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 3 * 1000
});
service.interceptors.request.use(config => {

  config.headers = {
    'Content-Type': 'application/json',
  }
  return config
}, error => {
  Promise.reject(error)
});
service.interceptors.response.use(response => {
  //接收到响应数据并成功后的一些共有的处理，关闭loading等
  return response
}, error => {
  console.log(error);
  /***** 处理结束 *****/
  //如果不需要错误处理，以上的处理过程都可省略
  return Promise.resolve(error.response)
})
//4.导入文件
export default service