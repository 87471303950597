import { getIndex } from '@/api/index.js';
import RichText from '@/pages/rich/index.vue';
const state = {
  config: {},
  columnTypeArray: [],
  isGet: false,
}

const mutations = {
  SET_CONFIG: (state, config) => {
    state.config = config
  },
  SET_COLUMN: (state, columnTypeArray) => {
    state.columnTypeArray = columnTypeArray
  },
  SET_GET: (state, isGet) => {
    state.isGet = isGet
  }
}

const actions = {
  async getConfig({ commit }) {
    let { data } = await getIndex();
    commit('SET_CONFIG', data.data);
    let { column_list, column_list_1 } = data.data;
    let columnTypeArray = [...column_list, ...column_list_1].filter(el => el.type === 2) || [];
    commit('SET_COLUMN', columnTypeArray);
    commit('SET_GET', true);
  },
  permissionRoute() {
    let asyncRoutes = state.columnTypeArray.map(el => {
      return {
        path: el.jump_url,
        meta: {
          title: el.title,
          id: el.id,
        },
        component: RichText,
      }
    });
    return asyncRoutes;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
