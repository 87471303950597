<template>
	<div id="app-main" class="container">
		<HeaderBox />
		<div class="container-box">
			<router-view></router-view>
		</div>
		<FooterBox />
    <t-back-top
      container=".container"
      :visible-height="200"
      style="position: absolute"
      :offset="['40px', '60px']"
      shape="circle"
    />
    <t-space>
      <t-sticky-tool
        :style="{ position: 'absolute', overflow: 'hidden' }"
        :offset="['-40px', '120px']"
      >
        <t-sticky-item
          trigger="click"
          label="客服"
          :icon="renderQrIcon"
          :popup="renderPopup"
          :popup-props="{ overlayInnerStyle: { padding: '4px' } }"
        >
        </t-sticky-item>
      </t-sticky-tool>
    </t-space>
	</div>
</template>
<script>
import HeaderBox from '@/layout/header.vue'
import FooterBox from '@/layout/footer.vue'
import { QrcodeIcon } from 'tdesign-icons-vue';
import { getCodeList } from '@/api';
export default {
	name: 'layout-comp',
	components: {
		HeaderBox,
		FooterBox,
	},
  data() {
    return {
      code_path: [],
    }
  },
  mounted() {
    this.getCode();
  },
  methods: {
    renderQrIcon() {
      return <QrcodeIcon />;
    },
    renderPopup() {
      if (!this.code_path.length) return'暂无客服';
      let nodes = this.code_path.map(el => {
        return (<div class="qr-box">
              <img width="120" height="120" src={el.code_path} />
              <div>{el.name}</div>
            </div>)
      });
      return nodes;
    },
    async getCode() {
      let {data} = await getCodeList();
      if (!data.data || !data.data.length) return'暂无客服';
      this.code_path = data.data;
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
	height: 100vh;
	display: flex;
	flex-direction: column;
  overflow: hidden auto;
  scroll-behavior: smooth;
	.container-box {
		flex: 1;
    background: #f5f5f5;
	}
}
::v-deep {
  .t-sticky-tool {
    z-index: 11;
  }
}
.qr-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
