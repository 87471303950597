import store from '@/store';
import router from '@/router'

router.beforeEach(async(to, from, next) => {
  let { isGet } = store.getters;
  if (!isGet) {
    await store.dispatch('app/getConfig');
    let asyncRoutes = await store.dispatch('app/permissionRoute');
    if (asyncRoutes.length) {
      for(let i = 0; i < asyncRoutes.length; i++) {
        router.addRoute('/', asyncRoutes[i]);
      }
    }
    next({ ...to, replace: true })
  } else {
    next();
  }
})