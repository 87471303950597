<template>
  <div class="rich-container">
    <HeaderBox />
		<div class="container-box">
			<div v-html="context" class="container-width"></div>
		</div>
		<FooterBox />
  </div>
</template>
<script>
import HeaderBox from '@/layout/header.vue'
import FooterBox from '@/layout/footer.vue'
export default {
  components: {
		HeaderBox,
		FooterBox,
	},
  computed: {
    context() {
      let column = this.$store.getters.column;
      console.log(this.$route);
      let { id } = this.$route.meta;
      let obj = column.find(el => el.id == id);
      return obj.text_content
    }
  }
}
</script>
<style lang="scss" scoped>
.rich-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  scroll-behavior: smooth;
  .container-box {
    flex: 1;
    background: #f5f5f5;
  }
}
</style>